// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

let firebaseConfig = {};
var actionCodeSettings = {};

console.log(process.env.REACT_APP_FIREBASE_PROJID);
if (process.env.REACT_APP_FIREBASE_PROJID === "demo") {
  // Dev config
  firebaseConfig = {
    apiKey: 'AIzaSyD3L8Iq9aTQua9iPDW7JnweHh2iVMoV8Cc',
    authDomain: 'consolidatademo.firebaseapp.com',
    projectId: 'consolidatademo',
    storageBucket: 'consolidatademo.appspot.com',
    messagingSenderId: '714930585313',
    appId: '1:714930585313:web:1fc5fcaf4bdd5758b901a0',
    measurementId: 'G-KXQB7YWPQT'
  };
  actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'https://dev.consolidata.one', // dev
    // This must be true.
    handleCodeInApp: true
  };
} else if (process.env.REACT_APP_FIREBASE_PROJID === "prod") {
  // Prod config
  firebaseConfig = {
    apiKey: 'AIzaSyD_mRhSplpbnm9lTZmgSVTYk8Ts_5l3qmQ',
    authDomain: 'consolidata-app.firebaseapp.com',
    databaseURL: 'https://consolidata-app.firebaseio.com',
    projectId: 'consolidata-app',
    storageBucket: 'consolidata-app.appspot.com',
    messagingSenderId: '575226816896',
    appId: '1:575226816896:web:666902c1db9769f257b965',
    measurementId: 'G-624L799M24'
  };
  actionCodeSettings = {
    url: 'https://app.consolidata.one', // demo
    handleCodeInApp: true
  };
} else {
  // beta config
  firebaseConfig = {
    apiKey: "AIzaSyBO8DWlzm55KeygKO79qSslR6ZBVb3ZxUI",
    authDomain: "consolidata-beta.firebaseapp.com",
    projectId: "consolidata-beta",
    storageBucket: "consolidata-beta.appspot.com",
    messagingSenderId: "713854210215",
    appId: "1:713854210215:web:b0ba929947cfecf47c4b82",
    measurementId: "G-K7M98XTFQK"
  };
  actionCodeSettings = {
    url: 'https://beta.consolidata.one', // beta
    handleCodeInApp: true
  };
}

//Initializing Firebase App and Storing in Variables for use via exports.
//const firebaseApp =
firebase.initializeApp(firebaseConfig);
//const db = firebaseApp.firestore();
const auth = firebase.auth();
var db = firebase.firestore();
var functionLoc = {
  funcLoc:
    'https://us-central1-' + firebaseConfig.projectId + '.cloudfunctions.net/'
};
if (window.location.hostname === 'localhost') {
  // Change above to just "localhost" if using the emulator for firestore and cloud functions
  db.settings({
    host: 'localhost:8080',
    ssl: false
  });
  functionLoc.funcLoc =
    'http://localhost:5001/' + firebaseConfig.projectId + '/us-central1/';
}
export const fbFunctionsURL = { funcURL: functionLoc.funcLoc };
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export { auth, googleAuthProvider, actionCodeSettings };
export default db;
