import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';

//Middlewares
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from './rootReducer';

let middlewares = [];

if (process.env.NODE_ENV === "development") {
    middlewares = [logger, thunk];
} else {
    middlewares = [thunk];
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);
