import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * Handles tabs display in app bar
 * @param {*} props 
 * - entities
 * - statements: list of financial statements
 * - location : value obtained from react router uselocation
 * @returns 
 */
const ScrollableTabs = (props) => {
  const { entities, statements, location } = props;

  const pathnameToTabItems = {
    'model/': {'items': entities, 'linkName':'/OperatingModelSummary'},
    'OperatingModelSummary': {'items': entities, 'linkName':'/OperatingModelSummary'},
    'financial/': {'items': statements, 'linkName': '/FinancialModelSummary'},
    'actuals/': {'items': ['Integrations', 'Uploads']}
  }

  let tabItems = [];

  // if name matches paths keys return the corresponding paths value
  for(const [key, value] of Object.entries(pathnameToTabItems)) {
    if(location.pathname.slice(1).includes(key)) {
      if(value['linkName'] === '/OperatingModelSummary') {
        // Takes care of displaying objects
        tabItems = value['items'].map((item) => (
          <span key={item.name} className="tabItems">
            <NavLink className="navlink-item capitalize" to={`/model/${item.id}`}>
              {item.name}
            </NavLink>
          </span>
        ));

        // Adding operationg Model Summary tab
        let summaryItem = (
          <span key="summary" className="tabItems">
            <NavLink
              className="navlink-item capitalize"
              to="/OperatingModelSummary">
              Summary
            </NavLink>
          </span>
        );
        tabItems.splice(0, 0, summaryItem);
      } else {
        // Takes care of displaying arrays
        tabItems = value['items'].map((item) => (
          <span key={item} className="tabItems">
            <NavLink className="navlink-item capitalize" to={`/${key}${item}`}>
              {item}
            </NavLink>
          </span>
        ));
      }
    }
  }

  return <div className="d-flex scrollable">{tabItems}</div>;
};

export default ScrollableTabs;
