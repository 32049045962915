const INITIAL_STATE = {
    streamsToInstances: { instance: {}, stream: {} }
  };
    
  const StreamsToInstancesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_STREAMS_TO_INSTANCES':
        return {
          ...state,
          streamsToInstances: action.payload
        };
      default:
        return state;
    }
  };
  
  export default StreamsToInstancesReducer;