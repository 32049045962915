import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import SuspenseLoading from './utils/SuspenseLoading';
import { pageVariants, pageTransition } from './utils/PageUtils';
import { connect } from 'react-redux';
import MuiTheme from './theme';
import UnauthenticatedRoute from './utils/UnauthenticatedRoute';
import AuthenticatedRoute from './utils/AuthenticatedRoute';
import LeftSidebar from './components/LeftSidebar';
import MinimalLayout from './components/MinimalLayout';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const ModelArchitecture = lazy(() =>
  import('./pages/ModelArchitecture')
);
const IncomeStatement = lazy(() => import('./pages/IncomeStatement'));
const CashFlowStatement = lazy(() =>
  import('./pages/CashFlowStatement')
);
const OperationalModel = lazy(() => import('./pages/OperationalModel/index'));
const FinancialModel = lazy(() => import('./pages/FinancialModel/index'));
const BalanceSheet = lazy(() => import('./pages/BalanceSheet'));
const WorkingCapital = lazy(() => import('./pages/WorkingCapital'));
const CashFlowReport = lazy(() => import('./pages/CashFlowReport'));
const BudgetVsActual = lazy(() => import('./pages/BudgetVsActual'));
const BudgetVersions = lazy(() => import('./pages/BudgetVersions'));
const Analysis = lazy(() => import('./pages/Analysis'));
const Assignments = lazy(() => import('./pages/Assignments'));
const Reviews = lazy(() => import('./pages/Reviews'));
const AccountDetails = lazy(() => import('./pages/AccountDetails'));
const Mappings = lazy(() => import('./pages/Mappings'));
const Actuals = lazy(() => import('./pages/Actuals'));
const UploadActuals = lazy(() => import('./pages/Actuals/uploadActuals'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword'));
const PageError404 = lazy(() => import('./pages/PageError404'));
const PageError500 = lazy(() => import('./pages/PageError500'));
const PageHolder = lazy(() => import('./pages/PageHolder'));
const Collaboration = lazy(() => import('./pages/Collaboration'));
const OperatingModelSummary = lazy(() => import('./pages/OperatingModelSummary'));
const FinancialModelSummary = lazy(() => import('./pages/FinancialModelSummary'));

//Routes
const Routes = () => {
  const location = useLocation();
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/Login" />
            <Route
              path={[
                '/Login',
                '/PageLoginOverlay',
                '/SignUp',
                '/RecoverPassword',
                '/PageError404',
                '/PageError500',
                '/PageHolder'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <UnauthenticatedRoute path="/Login" component={Login} />
                    <UnauthenticatedRoute path="/SignUp" component={SignUp} />
                    <UnauthenticatedRoute
                      path="/RecoverPassword"
                      component={RecoverPassword}
                    />
                    <Route path="/PageError404" component={PageError404} />
                    <Route path="/PageError500" component={PageError500} />
                    <Route path="/PageHolder" component={PageHolder} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <AuthenticatedRoute path="/Dashboard" component={Dashboard} />
                  <AuthenticatedRoute
                    path="/IncomeStatement"
                    component={IncomeStatement}
                  />
                  <AuthenticatedRoute
                    path="/CashFlowStatement"
                    component={CashFlowStatement}
                  />
                  <AuthenticatedRoute path="/BalanceSheet" component={BalanceSheet} />
                  <AuthenticatedRoute path="/WorkingCapital" component={WorkingCapital} />
                  <AuthenticatedRoute path="/CashFlowReport" component={CashFlowReport} />
                  <AuthenticatedRoute path="/BudgetVsActual" component={BudgetVsActual} />
                  <AuthenticatedRoute path="/BudgetVersions" component={BudgetVersions} />
                  <AuthenticatedRoute path="/Analysis" component={Analysis} />
                  <AuthenticatedRoute path="/Assignments" component={Assignments} />
                  <AuthenticatedRoute path="/Reviews" component={Reviews} />
                  <AuthenticatedRoute path="/AccountDetails" component={AccountDetails} />
                  <AuthenticatedRoute path="/Mappings" component={Mappings} />
                  <AuthenticatedRoute path="/Collaboration" component={Collaboration} />
                  <AuthenticatedRoute path="/Actuals/integrations" component={Actuals} />
                  <AuthenticatedRoute path="/Actuals/uploads" component={UploadActuals} />
                  <AuthenticatedRoute
                    path="/ModelArchitecture"
                    component={ModelArchitecture}
                  />
                  <AuthenticatedRoute path="/Model/:id" component={OperationalModel} />
                  <AuthenticatedRoute path="/OperatingModelSummary" component={OperatingModelSummary} />
                  <AuthenticatedRoute path="/FinancialModelSummary" component={FinancialModelSummary} />
                  <AuthenticatedRoute path="/financial/:id" component={FinancialModel} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps)(Routes);
