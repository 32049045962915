const INITIAL_STATE = {
  properties: []
};

const PropertiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PROPERTIES':
      return {
        ...state,
        properties: action.payload
      };
    default:
      return state;
  }
};

export default PropertiesReducer;
