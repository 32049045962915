import React, { useEffect, useState, useRef, useCallback } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink, useHistory  } from 'react-router-dom';
import { setLineItems } from 'redux/actions/LineItems';
import db from '../../firebase';
import { setSidebarToggleMobile } from '../../redux/reducers/ThemeOptions';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CachedIcon from '@material-ui/icons/Cached';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './index.scss';
import AddStatements from './AddStatements';

const SidebarMenu = ({ entities, setSidebarToggleMobile, userAccountDetails, setLineItems, lineItems }) => {
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  const [open, setOpen] = useState(false);
  const [reportsActive, setReportsActive] = useState(false);
  const [listOpen, setListOpen] = useState({
    modelsAssumption: false, 
    operatingModel: false, 
    financialModel: false, 
    actions: false, 
  });
  const [financials, setFinancials] = useState([]);
  const mountedRef = useRef(true);

  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    setReportsActive(!reportsActive);
    setOpen(!open);
  };

  const handleClickList = (name) => {
    setListOpen({
      ...listOpen,
      [name]: !listOpen[name] 
    });
  };

  const handleSummaryToggle = (name) => {
    handleClickList(name);

    if (name === 'operatingModel') {
      setTimeout(() => {
        history.push('/OperatingModelSummary');
      }, 100);
    }
  };

  const getStatements = useCallback(() => {
    let statements = new Set();
    db.collection('lineItems')
      .where('companyRef', '==', userAccountDetails.companyRef)
      .get()
      .then((doc) => {
        const array = [];
        doc.forEach((d) => {
          const data = d.data();
          data.id = d.id;
          statements.add(data['financialStatement']);
          array.push(data);
        });
        setFinancials(Array.from(statements.keys()));
        setLineItems(array);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getStatements();
    return () => { 
      mountedRef.current = false
    }
  }, []);

  const [chartsOpen] = useState(false);

  return (
    <PerfectScrollbar>
      <List>
        <div className="sidebar-navigation">
          <ul>
            <li>
              <NavLink to="/Dashboard">Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/ModelArchitecture">Model Architecture</NavLink>
            </li>
            <li
              className="sidebar-nav-link listItem pointer"
              onClick={() => handleClickList('operatingModel')} >
              <span className="sidebarHeader">
                  <span
                    className="sidebar-head"
                    onClick={() => handleSummaryToggle('operatingModel')}>
                      Operating Model
                  </span>
                  <span className="right">
                    {listOpen['operatingModel']
                      ? <ExpandLess />
                      : <ExpandMore />}
                  </span>
              </span>
            </li>
          </ul>
          <Collapse 
            in={listOpen['operatingModel']}
            timeout="auto"
            unmountOnExit>
            <ul>
              {entities.map((entity, i) => {
                return (
                  <li key={i}>
                    <NavLink
                     style={{paddingLeft: '35px'}}
                     to={`/model/${entity.id}`}>
                      <span className="sidebar-icon">
                        <ApartmentIcon />
                      </span>
                      <span className="ellipsis">{entity.name}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </Collapse>
          <ListItem
            className="listItem pointer"
            onClick={() => handleClickList('financialModel')}>
            <ListItemText>
              <span className="sidebarHeader">Financial Model</span>
            </ListItemText>
            {listOpen['financialModel'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={listOpen['financialModel']}
            timeout="auto"
            unmountOnExit>
            <ul>
              {financials.map((entity, i) => {
                return (
                  <li key={i}>
                    <NavLink
                      style={{ paddingLeft: '35px' }}
                      to={`/financial/${entity}`}>
                      <span className="sidebar-icon">
                        <DescriptionIcon />
                      </span>
                      <span className="sidebar-item-label">{entity}</span>
                    </NavLink>
                  </li>
                );
              })}
              <li>
                <AddStatements companyRef={userAccountDetails.companyRef} refresh={getStatements}/>
              </li>
            </ul>
          </Collapse>
          <ListItem
            className="listItem pointer"
            onClick={() => handleClickList('actions')}>
            <ListItemText>
              <span className="sidebarHeader">Actions</span>
            </ListItemText>
            {listOpen['actions'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={listOpen['actions']} timeout="auto" unmountOnExit>
            <ul>
              <li>
                <NavLink
                  // activeClassName={reportsActive ? "active": ""}
                  activeClassName=""
                  className="nav-link-simple"
                  style={{ paddingLeft: '35px' }}
                  to=""
                  onClick={handleClick}>
                  <span className="sidebar-icon">
                    <LibraryBooksIcon />
                  </span>
                  <span className="sidebar-item-label">Reports</span>
                </NavLink>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <ul>
                    <li>
                      <NavLink
                        activeClassName="active"
                        className="nav-link-simple"
                        to="/CashFlowReport">
                        Cash Flow
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        onClick={toggleSidebarMobile}
                        className="nav-link-simple"
                        to="/BudgetVsActual">
                        Budget vs Actual
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        onClick={toggleSidebarMobile}
                        className="nav-link-simple"
                        to="/BudgetVersions">
                        Budget Versions
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link-simple"
                  style={{ paddingLeft: '35px' }}
                  to="/actuals/integrations">
                  <span className="sidebar-icon">
                    <CachedIcon />
                  </span>
                  Actuals
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={{ paddingLeft: '35px' }}
                  to="/Analysis"
                  className={clsx({ active: chartsOpen })}>
                  <span className="sidebar-icon">
                    <TrendingUpIcon />
                  </span>
                  <span className="sidebar-item-label">Analysis</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link-simple"
                  style={{ paddingLeft: '35px' }}
                  to="/Assignments">
                  <span className="sidebar-icon">
                    <AssignmentIndIcon />
                  </span>
                  Assignments
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link-simple"
                  style={{ paddingLeft: '35px' }}
                  to="/Collaboration">
                  <span className="sidebar-icon">
                    <GroupAddIcon />
                  </span>
                  Collaboration
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link-simple"
                  style={{ paddingLeft: '35px' }}
                  to="/Reviews">
                  <span className="sidebar-icon">
                    <VisibilityIcon />
                  </span>
                  Reviews
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </div>
      </List>
    </PerfectScrollbar>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile,
  entities: state.entities.entities,
  userAccountDetails: state.userAccountDetails.details,
  lineItems: state.lineItems.lineItems
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setLineItems: (data) => dispatch(setLineItems(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
