const INITIAL_STATE = {
    company: {companyRef: "", name: "", forecastStartDate: ""},
  }
  
  const CompanyReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
      case 'SET_COMPANY':
        return {
          ...state,
          company: action.payload
        }
      default:
        return state
    }
  }
  
  export default CompanyReducer
  