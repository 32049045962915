import { auth } from '../firebase';

const reusableFetch = async (url, method, body) => {

    try {
      const token = await auth
      .currentUser
      .getIdToken()

      let response;
      
      switch (method) {
        case "GET":
          response = await fetch(url,
            {
              method: method,
              headers: {
                'Access-Control-Request-Method': method,
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`,
                'Origin': '*'
              }
            }
          )
          break;
        default:
          response = await fetch(url,
            {
              method: method,
              headers: {
                'Access-Control-Request-Method': method,
                'Authorization': `Bearer ${token}`,
                'Origin': '*'
              },
              body: JSON.stringify(body)
            }
          )
          break;
      }
      
      if (!response.ok) {
        throw new Error(response.status_text);
      }
      return response.json();
    }
    catch(err) {
      console.log(err);
    }
}

export default reusableFetch;