import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../redux/reducers/ThemeOptions';
import { withRouter } from 'react-router-dom';
import HeaderUserbox from '../HeaderUserbox';
import Tabs from './tabs';
import './index.scss';

// list of pathnames and display names
const paths = {
  'model/': 'operating Model',
  'financial/': 'financial Model',
  'actuals/': 'actuals'
}

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    entities,
    location,
    lineItems
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  /**
   * Format the pathname for display in the header
   * @param {*} name - pathname
   * @returns string, name to display
   */
  const formatName = (name) => {
    let i,
      result = '';

    // if name matches paths keys return the corresponding paths value
    for(const [key, value] of Object.entries(paths)) {
      if(name.includes(key)) {
        return value;
      }
    }

    // capitalize and space the name
    for(i in name) {
      if(name[i] === name[i].toUpperCase() && i !== 0)
       result += ' ' + name[i];
      else
        result += name[i];
    }
    return result;
  };
  
  // Get list of financial statements to display
  let statementsSet = new Set();
  lineItems.forEach((d) => statementsSet.add(d['financialStatement']));
  let statements = Array.from(statementsSet.keys());

  return (
    <div
      className={clsx('app-header', {
        'app-header--shadow': headerShadow,
        'app-header--opacity-bg': headerBgTransparent
      })}>
      <div className="app-header--pane">
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h5
            className="remove-margin capitalize"
            style={{ marginTop: '20px', paddingLeft: '8px' }}>
            {formatName(location.pathname.slice(1))}
          </h5>
          <Tabs
            entities={entities}
            statements={statements}
            location={location}
          />
        </div>
      </div>
      <div className="app-header--pane">
        <HeaderUserbox />
      </div>
    </div>
  );
};

const HeaderWithRouter = withRouter(Header);

const mapStateToProps = (state) => ({
  headerShadow: state.themeOptions.headerShadow,
  headerBgTransparent: state.themeOptions.headerBgTransparent,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile,
  entities: state.entities.entities,
  lineItems: state.lineItems.lineItems
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithRouter);
