import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import db, { fbFunctionsURL } from '../../firebase';
import { connect } from 'react-redux';
import { setEntities } from '../../redux/actions/Entities';
import { setProperties } from 'redux/actions/Properties';
import { setStreams } from 'redux/actions/Streams';
import { setCompany } from '../../redux/actions/Company';
import { setStreamsToInstances } from 'redux/actions/StreamsToInstances';
import { setSidebarToggleMobile } from '../../redux/reducers/ThemeOptions';
import SidebarMenu from '../SidebarMenu';
import SidebarHeader from '../SidebarHeader';
import reusableFetch from '../../utils/ReusableFetch';

const Sidebar = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarStyle,
    sidebarShadow,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setEntities,
    setProperties,
    setStreams,
    userAccountDetails,
    setCompany,
    setStreamsToInstances
  } = props;

  const mountedRef = useRef(true);

  const getEntities = async() => {
    try {
      const entityIds = [];
      const doc = await db.collection('entities')
      .where("companyRef", "==", userAccountDetails.companyRef)
      .get();
      
      const array = [];
      doc.forEach((d) => {
        const data = d.data();
        data.id = d.id;
        entityIds.push(d.id);
        array.push(data);
      });
      
      setEntities(array);
      return entityIds;
    } catch (error) {
      console.log(error);
    }
  };

  const getProperties = (entityIds = []) => {
    if (entityIds.length > 0) {
      db.collection('properties')
      .where('entityRef', 'in', entityIds)
      .get()
      .then((doc) => {
        let array = [];
        doc.forEach((d) => {
          let data = d.data();
          data.id = d.id;
          array.push(data);
        });
        setProperties(array);
      })
      .catch((err) => console.log(err));
    } else {
      setProperties([]);
    }
  };

  const getStreams = (entityIds = []) => {
    if (entityIds.length > 0) {
      db.collection('streams')
      .where('entityRef', 'in', entityIds)
      .get()
      .then((doc) => {
        let array = [];
        doc.forEach((d) => {
          let data = d.data();
          data.id = d.id;
          array.push(data);
        });
        setStreams(array);
      })
      .catch((err) => console.log(err));
    } else {
      setStreams([]);
    }
  };

  const getCompany = async() => {
    try {
      const companyRef = userAccountDetails.companyRef;

      if (companyRef) {
        const doc = await db.doc(`companies/${companyRef}`).get();
        if (doc.exists) {
          const docData = doc.data();
          docData.id = doc.id;
          //save to redux
          setCompany(docData);
        }    
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllStreamsValues = async () => {
    try {
      const fetchResult = await reusableFetch(
        fbFunctionsURL.funcURL +
        `getAllStreamsValues?reference=${userAccountDetails.companyRef}`,
        'GET'
      );
      if (fetchResult) setStreamsToInstances(fetchResult);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getData() {
      const entityIds = await getEntities();
      getProperties(entityIds);
      getStreams(entityIds);
      getAllStreamsValues();
    };

    getData();
    getCompany();
    return () => { 
      mountedRef.current = false;
    }
  }, []);

  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow
        })}>
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarShadow: state.themeOptions.sidebarShadow,
  sidebarFooter: state.themeOptions.sidebarFooter,
  sidebarStyle: state.themeOptions.sidebarStyle,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile,
  userAccountDetails: state.userAccountDetails.details
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setEntities: (data) => dispatch(setEntities(data)),
  setProperties: (data) => dispatch(setProperties(data)),
  setStreams: (data) => dispatch(setStreams(data)),
  setCompany: (data) => dispatch(setCompany(data)),
  setStreamsToInstances: (data) => dispatch(setStreamsToInstances(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
