const INITIAL_STATE = {
  lineItems: [],
}

const LineItemsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'SET_LINEITEMS':
      return {
        ...state,
        lineItems: action.payload
      }
    default:
      return state
  }
}

export default LineItemsReducer
