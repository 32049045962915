const INITIAL_STATE = {
  entities: [],
  currentEntity: null,
}

const EntitiesReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'SET_ENTITIES':
      return {
        ...state,
        entities: action.payload
      }
    case 'SET_CURRENT_ENTITY':
      return {
        ...state,
        currentEntity: action.payload
      }
    default:
      return state
  }
}

export default EntitiesReducer
