const INITIAL_STATE = {
    details: {companyRef: ""}
  };
  
  const userAccountDetails = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_USERDETAILS':
        return {
          ...state,
          details: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userAccountDetails;
  