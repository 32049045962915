import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import user from './reducers/User';
import themeOptions from './reducers/ThemeOptions';
import entities from './reducers/Entities';
import properties from './reducers/Properties';
import streams from './reducers/Streams';
import userAccountDetails from './reducers/UserAccountDetails';
import lineItems from './reducers/LineItems';
import company from './reducers/Company';
import streamsToInstances from './reducers/StreamsToInstances';

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'entities',
    'properties',
    'streams',
    'userAccountDetails',
    'lineItems',
    'company',
    'streamsToInstances'
  ]
};

export const rootReducer = combineReducers({
  user,
  themeOptions,
  entities,
  properties,
  streams,
  userAccountDetails,
  lineItems,
  company,
  streamsToInstances
});

export default persistReducer(persistConfig, rootReducer);