import React from 'react';
import clsx from 'clsx';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first">
          <span>
            Have a question or need support? Contact Us: info@consolidata.one
          </span>
        </div>
        <div className="app-footer--second">
          <span>Copyright</span> ©
          Consolidata 2021
        </div>
      </div>
    </>
  );
};

export default Footer;
