import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";


const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  let redirectPath;

  if (rest.user) {
    redirectPath = "/Dashboard";
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return !rest.user ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect to={redirectPath} />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, null)(UnauthenticatedRoute);

