import React, { useEffect, useState } from 'react';
import db from '../../firebase';
import {
  Popover,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import './index.scss';

/**
 * This component enables a popover to create a new financial for users.
 * @param {*} param0 - company ID and a refresh function for the lineItems state
 * @returns 
 */
const AddStatements = ({companyRef, refresh}) => {
  const [anchorSign, setAnchorSign] = useState(null);
  const [statementName, setStatement] = useState(null);

  const openPopOver = Boolean(anchorSign);
  const popOver = Boolean(anchorSign) ? 'simple-popover' : undefined;
  const handleClick = (e) => {
    setAnchorSign(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorSign(null);
  };
  const handleAdd = async () => {
    try {
      // new line item data
      const newLineItem = {
        name: "Initial Line Item",
        companyRef: companyRef,
        financialStatement: statementName,
        position: 1,
        streamRefs: [],
      };

      // add lineitem to firestore
      const newDoc = await db.collection('lineItems').add(newLineItem);
      console.log(newDoc.id);
      setAnchorSign(null);
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Typography
        id={popOver}
        style={{ 
        paddingLeft: '35px', 
        color: 'rgba(255,255,255,0.3)',
        textAlign: 'left', 
        }}
        onClick={handleClick}
        className="nav-link-simple">
        <span className="sidebar-icon">
          <LibraryAddIcon />
        </span>
      </Typography>
      <Popover
        id={popOver}
        open={openPopOver}
        anchorEl={anchorSign}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Typography component={'div'} className="padding-all popover-content">
          <div className="row-justify poper-width-300">
            <TextField
              size="small"
              label={`Type Statement Name`}
              variant="outlined"
              style={{ width: '250px' }}
              onChange={(event) => setStatement(event.target.value)}
            />
          </div>
          <div className="row-justify">
            <Button
              size="small"
              className="btn-neutral-success"
              style={{ marginTop: '20px' }}
              onClick={handleAdd}>
              <span className="btn-wrapper--label">Add</span>
            </Button>
          </div>
        </Typography>
      </Popover>
    </>
  );
};

export default AddStatements;
